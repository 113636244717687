import React, { useCallback, useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useShoppingCart } from "../contexts/shoppingCart";
import { actions } from "../contexts/shoppingCart/actions";
import ReactHtmlParser from "react-html-parser";

export const ProductPreview = ({ product, slug, redirectToDetail }) => {
  const [{ client, checkout }, dispatch] = useShoppingCart();
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);

  const handleAddToCart = useCallback(() => {
    actions.addLineItem(dispatch, client, checkout, {
      variantId: selectedVariant.shopifyId,
      quantity: 1,
    });
  }, [dispatch, client, checkout, selectedVariant]);

  const handleSelectVariant = useCallback(
    (shopifyId) => {
      setSelectedVariant(
        product.variants.find((v) => v.shopifyId === shopifyId)
      );
    },
    [product.variants]
  );

  return (
    <div className="tile is-parent is-4">
      <div className="tile is-child card">
        <div className="card-image">
          <Link to={`${slug}/${product.handle}`}>
            <figure className="image">
              {product?.media && product?.media?.length > 0 ? (
                <GatsbyImage
                  image={product?.media[0]?.preview?.image?.gatsbyImageData}
                  alt={product.handle}
                />
              ) : (
                <span></span>
              )}
            </figure>
          </Link>
        </div>

        <div className="card-content">
          <div className="content pb-5">
            <Link to={`${slug}/${product.handle}`}>
              <p className="title is-5">{product.title}</p>
            </Link>

            <hr />
            {redirectToDetail ? null : product.variants.length === 1 ? (
              <p className="is-pulled-left subtitle is-4">
                &#x20B9;
                {Math.floor(parseFloat(selectedVariant.price)).toLocaleString()}
              </p>
            ) : (
              <div className="select is-small">
                <select
                  onBlur={(evt) => {
                    handleSelectVariant(evt.target.value);
                  }}
                >
                  {product.variants.map((v) => (
                    <option value={v.shopifyId} key={v.shopifyId}>
                      {v.title} &#x20B9;
                      {Math.floor(parseFloat(v.price)).toString()}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {redirectToDetail ? (
              <Link to={`${slug}/${product.handle}`}>
                <button className="button is-primary is-small is-pulled-right">
                  Learn More
                </button>
              </Link>
            ) : (
              <button
                className="button is-primary is-small is-pulled-right"
                onClick={handleAddToCart}
              >
                Add to cart
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductDetail = ({ product, checkInventory }) => {
  const [{ client, checkout }, dispatch] = useShoppingCart();
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [checkingInventory, setCheckingInventory] = useState(true);
  const [isSold, setIsSold] = useState(false);
  const handleAddToCart = useCallback(() => {
    actions.addLineItem(dispatch, client, checkout, {
      variantId: selectedVariant.shopifyId,
      quantity: 1,
    });
  }, [dispatch, client, checkout, selectedVariant.shopifyId]);

  const handleSelectImage = useCallback((i) => {
    setSelectedImageIndex(i);
  }, []);

  const handleGoBack = useCallback(() => {
    window.history.back();
  }, []);
  const handleSelectVariant = useCallback(
    (shopifyId) => {
      setSelectedVariant(
        product.variants.find((v) => v.shopifyId === shopifyId)
      );
    },
    [product.variants]
  );

  useEffect(() => {
    const checkAvailability = async () => {
      setCheckingInventory(true);
      try {
        if (client !== undefined && checkInventory) {
          const productResponse = await client.product.fetch(product.shopifyId);
          const currentVariant = productResponse.variants.find(
            (v) => v.id === selectedVariant.shopifyId
          );
          setIsSold(!currentVariant.available);
        }
      } catch (e) {
        // Assume there is no inventory
        setIsSold(false);
      } finally {
        setCheckingInventory(false);
      }
    };
    checkAvailability();
  }, [client, product.shopifyId, selectedVariant.shopifyId, checkInventory]);

  return (
    <div className="columns">
      <div className="column is-5">
        <GatsbyImage
          image={
            product?.media[selectedImageIndex]?.preview?.image?.gatsbyImageData
          }
          alt={product.handle}
        />
        {product?.media?.length > 1 ? (
          <div className="columns is-mobile mt-2">
            {product?.media?.map((image, i) => (
              <div
                className="column is-2"
                onClick={() => {
                  handleSelectImage(i);
                }}
                aria-hidden="true"
                key={`product-thumbnail-${i}`}
              >
                <GatsbyImage
                  style={
                    i === selectedImageIndex
                      ? { border: "4px dashed #041563", cursor: "pointer" }
                      : { cursor: "pointer" }
                  }
                  image={image.preview.image.gatsbyImageData}
                  alt={`${product.handle}-thumbnail-${i + 1}`}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="column is-5 has-text-left">
        <p className="title">{product.title}</p>

        <hr />
        <div className="content has-text-left">
          {ReactHtmlParser(product.descriptionHtml)}
        </div>
      </div>
      {checkingInventory ? (
        <div className="column is-2">
          <progress className="progress is-small is-info" max="100">
            15%
          </progress>
        </div>
      ) : (
        <div className="column is-2">
          {!checkInventory || (checkInventory && !isSold) ? (
            <div>
              {product.variants.length === 1 ? (
                <p className="title has-text-left">
                  &#x20B9;
                  {Math.floor(parseFloat(selectedVariant.price)).toString()}
                </p>
              ) : (
                <div className="select mb-2 has-text-left">
                  <select
                    onBlur={(evt) => {
                      handleSelectVariant(evt.target.value);
                    }}
                  >
                    {product.variants.map((v) => (
                      <option value={v.shopifyId} key={v.shopifyId}>
                        {v.title} &#x20B9;
                        {Math.floor(parseFloat(v.price)).toLocaleString()}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="has-text-left">
                <button className="button is-primary" onClick={handleAddToCart}>
                  Add to cart
                </button>
              </div>
              <div className="mt-2 has-text-left">
                <button className="button" onClick={handleGoBack}>
                  Go Back
                </button>
              </div>
            </div>
          ) : (
            <span className="tag is-info is-medium">SOLD</span>
          )}
        </div>
      )}
    </div>
  );
};
